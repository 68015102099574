
/* Light mode styles (less recommended) */
.rdp { /* Target the main DayPicker container class */
    background-color:transparent; /* Set background to white */
    color: black;
  }

/*days of week*/
.rdp-head_cell{
    color: #00000060;
}
.rdp-day_selected {
    background-color:  rgba(251, 174, 98, 1.0);
}

.rdp-day:hover{
    background-color: rgba(251, 174, 98, 1.0);
    color: var(--rdp-selected-color);
    border: 1px solid rgba(251, 174, 98, .6);

    /* background-color: transparent; */
}
.rdp-weeknumber:hover{
border: 1px solid rgba(251, 174, 98, .6);
background-color: white;
opacity: 1;
}
.rdp-button:hover:not([disabled]):not(.rdp-day_selected){
border: 1px solid rgba(251, 174, 98, .6);
background-color: transparent;
color: black;
}
  /* You might need to target other classes depending on your desired look */