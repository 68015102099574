/* App.css */
html {
  scroll-behavior: smooth;
}
body {
    /* font-family: 'Cereal', 'HelveticaNeue', 'Helvetica Neue', Roboto, Arial, sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', sans-serif;
    overflow-x: hidden;
}

h1{
  color: white;
  /* font-size: 4em;
  font-weight: 600; */
  font-weight: 200;
  font-size: 5em;
  margin-top: 0px;
  line-height: 95%;
  /* letter-spacing: 10px;  Adjust the value to control the spacing */

  /* text-shadow: 2px 2px 2px #00000060; Creates a white shadow */
}
h2{
    color: #3F3F3F;
    font-size: 3em;
    font-weight: 300;
    margin-top: 0px;
    line-height: 95%;
    /*text-shadow: 2px 2px 2px #00000060; Creates a white shadow*/
  padding: 0px 0px;
}

h3{
  font-size: 1.5em;
}


/* Media Query for Tablets (e.g., 768px and below) */
@media (max-width: 768px) {
  h1 {
      font-size: 3em; /* Reduce size */
  }
  h2 {
      font-size: 2.5em; /* Reduce size */
  }
}

/* Media Query for Mobile Devices (e.g., 480px and below) */
@media (max-width: 480px) {
  h1 {
      font-size: 2.5em; /* Further reduce size */
  }
  h2 {
      font-size: 2em; /* Further reduce size */
  }
}